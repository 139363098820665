import React from 'react';
import './App.css';
import { withRouter } from "react-router-dom";

import { Component } from "react";


const axios = require('axios').default;

class SharedDream extends Component{
	constructor(props) {
		super(props);
		this.state = {
			dream:''
		}
	}
	
	componentDidMount=()=>{


		
		// Make a request for a user with a given ID
		axios.get('https://europe-west2-mindream-17ced.cloudfunctions.net/sharedContent?id='+this.props.match.params.sharedDreamId)
		  .then( (response)=> {
			// handle success
			console.log(response.data.dream)
			this.setState({'dream':response.data.dream})
		  })
		  .catch( (error) =>{
			// handle error
			this.setState({'dream':'Such dream does not exist. Yet'})
			console.log(error);
		  })
		  .then( ()=> {
			// always executed
		  })
	}
	
	render(){
		return (
			<div className='container dream-shared'>
			
			<div className='dream'>
			{this.state.dream}
			</div>
			</div>
			
			
			
			)
		}
		
		
	}
	export default withRouter(SharedDream);
	
