import React from "react";
import "./App.css";
import { Component } from "react";

export class Privacy extends Component {
  render() {
    return (
      <div className="container long-text-container">
        <div className="low-width">
          <h1>Privacy Policy</h1>
          <p>Last updated: May 16, 2024</p>

          <h2>Introduction</h2>
          <p>
            We are committed to safeguarding the privacy of our users using our
            Services. This policy applies where we are acting as a data
            controller with respect to the personal data of such persons; in
            other words, where we determine the purposes and means of the
            processing of that personal data. In this policy, "we", "us" and
            "our" refer to Rabbit Theory Ltd. (the mobile application together
            with this website, the "Services").
          </p>
          <p>
            We acknowledge that your dreams are private, and we strive to keep
            them private and make our application's usage of data as transparent
            as possible. We intend to collect and use your personal information
            only for your benefit as our user. We never sell, rent, share, or
            give away your personal information to other companies for any gain,
            whether it's money or something else valuable. Information about how
            third-party services handle your data can be obtained from this
            link:{" "}
            <a href="https://firebase.google.com/terms/data-processing-terms">
              Firebase Data Processing and Security Terms
            </a>
          </p>

          <h2>The Personal Data We Collect</h2>
          <p>
            In this section, we outline the general categories of personal data
            that we may process. Dreamink is an application in which you can
            record your dreams and dream-related information. In order to make
            this app work, we need to store some data.
          </p>
          <h3>Contact Data</h3>
          <p>
            We may process data enabling us to get in touch with you ("contact
            data"). The contact data may include your email address. The source
            of the contact data is you when you register an account.
          </p>
          <h3>Account Data</h3>
          <p>
            We may process your user account data ("account data"). The account
            data may include your account identifier, email address, account
            creation and modification dates, and application settings. The
            primary source of the account data is you, although some elements of
            the account data may be generated by our app.
          </p>
          <p>
            We do not store, collect, or process your payment or credit card
            details.
          </p>
          <h3>User Content Data</h3>
          <p>
            We may process your user content data. When you create a dream, we
            create a set of information related to the dream. This information
            set may include the text you enter, symbols you enter, emotions you
            pick for your dream, and so on. The primary source of the account
            data is you, although some elements of the account data may be
            generated by our app.
          </p>
          <h3>Usage Data</h3>
          <p>
            We may process data about your use of our app and services ("usage
            data") in order to identify bugs, crashes, and to improve the app by
            understanding customer usage. The data included for these services
            are listed on this page:{" "}
            <a href="https://firebase.google.com/support/privacy">
              Privacy and Security in Firebase
            </a>
            . The source of the usage data is Google Analytics for Firebase and
            Firebase Crashlytics. This information is enabled by default for
            collection automatically when you sign in to the app.
          </p>
          <h3>Speech Recognition Data</h3>
          <p>
            If you opt-in to use the Speech Recognition feature in the app, your
            audio recordings will be hosted on online storage temporarily and
            then will be removed automatically after the recognition process is
            complete.
          </p>
          <h3>Sharing Functionality</h3>
          <p>
            The app has a sharing functionality. By using this functionality,
            the service will create a publicly available link that is accessible
            on the internet. By using the share functionality, you consent to
            the app making your dream available to the public. You can unshare a
            link you've shared previously from the app.
          </p>

          <h2>Providing Your Personal Data to Others</h2>
          <p>
            Your personal data will be stored on the servers of our service
            provider, Google Cloud. These servers are located in Europe. By
            submitting or transferring data within our Services, you consent to
            your data being hosted, transmitted, and processed on these servers.
          </p>
          <p>
            We use RevenueCat for managing in-app purchases such as "Premium"
            subscriptions.
          </p>
          <p>
            We use Google AdMob to serve ads on our app. Google AdMob may use
            your data to show you personalized ads based on your interests. You
            can learn more about how Google collects and uses data by visiting
            the{" "}
            <a href="https://policies.google.com/privacy">
              Google Privacy Policy
            </a>
            .
          </p>
          <p>
            In addition to the specific disclosures of personal data set out in
            this section, we may disclose your personal data where such
            disclosure is necessary for compliance with a legal obligation to
            which we are subject, or in order to protect your vital interests or
            the vital interests of another natural person. We may also disclose
            your personal data where such disclosure is necessary for the
            establishment, exercise, or defence of legal claims, whether in
            court proceedings or in an administrative or out-of-court procedure.
          </p>

          <h2>Purposes of Processing and Legal Bases</h2>
          <p>
            We may process your personal data for the purposes of operating,
            improving, and analyzing our app and providing our services. The
            legal basis for this processing is our legitimate interests, namely
            the proper administration of our app, services, and business.
          </p>
          <p>
            We may use your user account data to create and modify your account,
            verify your email, and to send you communication and emails
            according to your preference.
          </p>
          <p>
            We may process usage data and/or transaction data for the purposes
            of researching and analyzing the use of our services, as well as
            researching and analyzing other interactions with our business. The
            legal basis for this processing is our legitimate interests, namely
            supporting, improving, and securing our services and business
            generally.
          </p>
          <p>
            We may process personal data where necessary for the purposes of
            obtaining or maintaining insurance coverage, managing risks, and/or
            obtaining professional advice. The legal basis for this processing
            is our legitimate interests, namely the proper protection of our
            business against risks.
          </p>
          <p>
            We may process your personal data where necessary for the
            establishment, exercise, or defence of legal claims, whether in
            court proceedings or in an administrative or out-of-court procedure.
            The legal basis for this processing is our legitimate interests,
            namely the protection and assertion of our legal rights, your legal
            rights, and the legal rights of others.
          </p>
          <p>
            We may also process your personal data where such processing is
            necessary for compliance with a legal obligation to which we are
            subject or in order to protect your vital interests or the vital
            interests of another natural person.
          </p>

          <h2>Retaining and Deleting Personal Data</h2>
          <p>
            Personal data that we process for any purpose or purposes shall not
            be kept for longer than is necessary for that purpose or those
            purposes. We will retain your data as long as you have an active
            account on our services.
          </p>
          <p>
            You can initiate your account's deletion process from the "My
            Account" section inside the app. By taking an account deletion
            action, you acknowledge that your personal data will be permanently
            and irreversibly deleted.
          </p>
          <p>
            App usage and diagnostic data are anonymous; they are not linked to
            your account, and for this reason, they may be retained even after
            account deletion. Notwithstanding the other provisions of this
            section, we may retain your personal data where such retention is
            necessary for compliance with a legal obligation to which we are
            subject, or in order to protect your vital interests or the vital
            interests of another natural person.
          </p>
          <p>
            You can also request the personal data we retain for you by visiting
            the Contact Us page. Please note that if we detect that you make
            such requests too often or for any reason deemed with malicious
            intent, your request will not be responded to, and your account
            might be suspended.
          </p>

          <h2>GDPR</h2>
          <p>
            This Privacy Policy includes GDPR rights for our customers. Please
            read more to understand your rights about erasing or requesting
            access to your data we hold about you.
          </p>

          <h2>CCPA</h2>
          <p>
            This Privacy Policy includes California Consumer Privacy Act (CCPA)
            rights for our customers. Please read more to understand your rights
            about erasing or requesting access to your data we hold about you.
          </p>

          <h2>Amendments</h2>
          <p>
            We may update this policy from time to time by publishing a new
            version on our website. You should check this page occasionally to
            ensure you are happy with any changes to this policy. We may notify
            you of changes to this policy by posting a notification on our
            homepage. If you use the app after a privacy policy change, you
            understand that the new privacy policy applies to you.
          </p>

          <h2>Our Details</h2>
          <p>
            This website is owned and operated by Rabbit Theory Ltd. registered
            in England and Wales. If you would like to get more information
            about us please visit our website https://rabbittheory.com. If you
            would like to suggest a change or get in touch, you can contact us
            by visiting <a href="https://dreamink.app/contact">Contact Us</a>
          </p>
        </div>
      </div>
    );
  }
}
