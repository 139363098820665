import React from 'react';
import './App.css';
import { 
    Component,
} from "react";
import { connect } from 'react-redux';
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore"; 
import moment from 'moment';


class Dreams extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
            dreams:[]
        }
    }
    componentDidMount=()=>{
        try{
            const auth = getAuth(this.props.firebase)
            if(auth.currentUser){
                this.getDreams()
            }
        }catch(o){
            console.log('Error')
        }

    }

    getDreams=async()=>{
        if(this.props.firebase){
            var dreams=[]
            const db = getFirestore(this.props.firebase);
            const auth = getAuth(this.props.firebase)
            const uid =auth.currentUser.uid
            const ref = collection(db, 'users/'+uid+'/dreams')
            const q = query(ref, orderBy("date",'desc'))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                var dream=doc.data()
                dream['id']=doc.id
                dreams.push(dream)
            })
            this.setState({dreams:dreams})
        }

        
    }
    renderDreams=()=>{
        if(this.state.dreams.length>0){
            var arr=[]
            this.state.dreams.map((dream)=>{
                let forgottenDream=false
                if(dream.dream.length==''){
                    forgottenDream=true
                }
                arr.push(
                    <div className={!forgottenDream?'dream-container':'dream-container red'} key={dream.id}>
                    <p className='dream-text date'>{moment(dream.date.toDate()).format('DD MMMM, YYYY')}</p>
                    {forgottenDream?
                    <p className='dream-text centre'>?</p>
                    :
                    <p className='dream-text'>{dream.dream}</p>
                    }

                    <div className='flex-row'>
                    {dream.symbols.map((sym)=>{
                    return(<div key={sym} className='symbol'>{sym}</div>)
                    })}
                    </div>

                    </div>
                )
                

            })
            return arr
        }
    }
    render=()=>{
        return (
            <div className='container safe-area'>
            <div>{this.renderDreams()}</div>
            </div>
            
            
            )
        }
        
}
const mapStateToProps = (state) => {
    return {
        firebase:state.firebase
    };
}

export default connect(mapStateToProps)(Dreams);
    