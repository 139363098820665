import React from "react";
import { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

import "./App.css";
import { Privacy } from "./Privacy.js";
import { Terms } from "./Terms.js";
import { Acknowledgements } from "./Acknowledgements.js";
import { Contact } from "./Contact.js";

import Login from "./Login.js";
import Dreams from "./Dreams.js";

import SharedDream from "./SharedDream.js";
import { connect } from "react-redux";

import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = async () => {
    const auth = getAuth(this.props.app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setState({ pass: true });
      } else {
        this.setState({ pass: false });
      }
    });
  };
  componentWillUnmount = () => {};
  logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("User Logged out");
      })
      .catch((error) => {
        console.log("Error login out?");
      });
  };
  render = () => {
    return (
      <div className="container-web">
        <Router>
          <Switch>
            <Route path="/terms">
              <Terms />
            </Route>

            <Route path="/privacy">
              <Privacy />
            </Route>

            <Route path="/acknowledgements">
              <Acknowledgements />
            </Route>

            <Route path="/shared/:sharedDreamId">
              <SharedDream />
            </Route>

            <Route path="/login">
              {!this.state.pass ? <Login /> : <Redirect to="/dreams" />}
            </Route>

            <Route path="/dreams">
              {this.state.pass ? <Dreams /> : <Redirect to="/login" />}
            </Route>

            <Route path="/contact">
              <Contact />
            </Route>

            <Route path="/">
              <Home pass={this.state.pass} />
            </Route>
          </Switch>
          <footer>
            <Link to="/terms">Terms and Conditions</Link>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/acknowledgements">Acknowledgements</Link>
          </footer>
        </Router>
      </div>
    );
  };
}

// App.js
class Home extends Component {
  render() {
    return (
      <div className="container">
        <h1 className="centre">Dreamink</h1>
        <h2 className="centre">Your Dream Journal, AI Powered</h2>
        <a href="https://apps.apple.com/gb/app/dreamink/id1637114296">
          <img
            alt="app-logo"
            className="app-logo"
            src="./assets/dreamink.png"
          />
        </a>

        <a href="https://apps.apple.com/gb/app/dreamink/id1637114296">
          <img className="appstore-logo" src="./assets/appstore.svg" />
        </a>
        {this.props.pass && (
          <Link className="btn" to="/dreams">
            READ DREAMS
          </Link>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    firebase: state.firebase,
  };
};

export default connect(mapStateToProps)(App);
