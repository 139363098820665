import React from 'react';
import './App.css';
import { 
    Component,
} from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { connect } from 'react-redux';


class Login extends Component{
    constructor(props) {
        super(props);
        this.state = {
            errorMessage:'',
            email:null,
            password:null
        }
    }
    componentDidMount=()=>{

    }
    componentWillUnmount=()=>{
        this.setState({email:null,password:null})
    }
    handleChange=(e)=>{
        switch(e.target.name){
            case 'email':
            this.setState({email:e.target.value})
            break;
            case 'password':
            this.setState({password:e.target.value})
            break;
            default:
            break;
        }
    }
    loginPressed=()=>{
        try{
            const auth=getAuth(this.props.firebase)
            if(this.state.email && this.state.password){
                signInWithEmailAndPassword(auth, this.state.email, this.state.password).then((userCredential) => {
                    const user = userCredential.user;
                    console.log('Logged in')
                    this.setState({email:null,password:null})
                    // ...
                })
                .catch(() => {
                    this.setState({errorMessage:'Please check your credentials'})
                    console.log('Error')
                });
            }else{
                this.setState({errorMessage:'Something is not quite right'})
                console.log('No credentials')
            }

        }catch(o){
            console.log('CS Error')
        }

    }
    
    render=()=>{
        return (
            <div className='container'>
            
            
            <div className='login-container'>
            {this.state.errorMessage ? <p className='usable-text'>{this.state.errorMessage}</p>:<div/>}
            <form>
            <input autoComplete="username" placeholder='Enter email address' name='email' id="email" type="email" className="validate" onChange={(e)=>this.handleChange(e)} />            
            <input autoComplete="current-password" placeholder='Enter password' ref={this.passwordField} name='password' id="password" type="password" className="validate" onChange={(e)=>this.handleChange(e)} />
            </form>
            <div className='divider'/>
            <div className='margin two-rem'>
            <a ref={this.masterButton} className="btn auto-margin" onClick={()=>this.loginPressed()} >Log In</a>
            <p className='terms'>By continuing you agree to our <a href='/terms'>Terms and Conditions</a> and acknowledge that our <a href='/privacy'>Privacy Policy</a> applies to you</p>
            </div>
            </div>
            
            </div>
            
            
            )
        }
        
    }
    const mapStateToProps = (state) => {
        return {
            firebase:state.firebase
    
        };
    }
    
    export default connect(mapStateToProps)(Login);
    