import React from "react";
import "./App.css";
import { Component } from "react";

export class Contact extends Component {
  render() {
    return (
      <div className="container long-text-container centre">
        <div className="low-width">
          <h1>Contact </h1>
          <p>
            Hello! Let us know if you have any questions or just want to drop by
            to say hello by joining our new Discord server..
          </p>

          <a className="btn auto-margin" href="https://discord.gg/QRyMF4EyMW">
            JOIN OUR DISCORD SERVER
          </a>
        </div>
      </div>
    );
  }
}
