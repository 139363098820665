import React from "react";
import "./App.css";
import { Component } from "react";

export class Acknowledgements extends Component {
  render() {
    return (
      <div className="container long-text-container">
        <div className="low-width">
          <h1>Acknowledgements</h1>
          <p>
            Special thanks to all of these libraries and their dependent
            libraries that made it possible to create Dreamink.
          </p>
          <p>
            To view the license of a specific package, please visit
            https://www.npmjs.com/ and type the exact package name (as featured
            below) into the search bar.
          </p>
          <ul>
            <li>@babel/core</li>
            <li>@babel/runtime</li>
            <li>@react-native-community/eslint-config</li>
            <li>babel-jest</li>
            <li>eslint</li>
            <li>jest</li>
            <li>metro-react-native-babel-preset</li>
            <li>react-test-renderer</li>

            <li>@testing-library/jest-dom</li>
            <li>@testing-library/react</li>
            <li>@testing-library/user-event</li>
            <li>react-dom</li>
            <li>react-router-dom,</li>
            <li>react-scripts</li>
            <li>web-vitals</li>

            <li>@react-native-async-storage/async-storage</li>
            <li>@react-native-community/blur</li>
            <li>@react-native-community/push-notification-ios</li>
            <li>@react-native-community/slider</li>
            <li>@react-native-firebase/analytics</li>
            <li>@react-native-firebase/app</li>
            <li>@react-native-firebase/auth</li>
            <li>@react-native-firebase/firestore</li>
            <li>@react-native-firebase/functions</li>
            <li>@react-native-firebase/messaging</li>
            <li>@react-native-firebase/remote-config</li>
            <li>@react-navigation/bottom-tabs</li>
            <li>@react-navigation/native</li>
            <li>@react-navigation/native-stack</li>
            <li>i18n-js</li>
            <li>lottie-react-native</li>
            <li>moment</li>
            <li>moment-timezone</li>
            <li>react</li>
            <li>react-moment</li>
            <li>react-native</li>
            <li>react-native-audio-recorder-player</li>
            <li>react-native-date-picker</li>
            <li>react-native-fast-image</li>
            <li>react-native-fs</li>
            <li>react-native-get-random-values</li>
            <li>react-native-haptic-feedback</li>
            <li>react-native-linear-gradient</li>
            <li>react-native-localize</li>
            <li>react-native-safe-area-context</li>
            <li>react-native-screens</li>
            <li>react-native-svg</li>
            <li>react-redux</li>
            <li>redux</li>
            <li>uuid</li>
            <li>victory-native</li>
            <li>react-native-track-player</li>
            <li>react-native-biometrics</li>
            <li>react-native-siri-shortcut</li>
            <li>react-native-permissions</li>
            <li>react-native-purchases</li>

            <li>
              <a href="https://www.flaticon.com/">Freepik - Flaticon</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
