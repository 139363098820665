import React from "react";
import "./App.css";
import { Component } from "react";

export class Terms extends Component {
  render() {
    return (
      <div className="container long-text-container">
        <div className="low-width">
          <h1>Terms and Conditions</h1>
          <p>Last updated: May 16, 2024</p>
          <h2>Introduction</h2>
          <p>
            These terms and conditions govern your use of our services (the
            mobile application together with this website, the "Services"). Our
            mobile application and website are operated by Rabbit Theory Ltd
            ("Rabbit Theory", "us", "we"). By using our Services, you accept
            these terms and conditions in full; if you disagree with these terms
            and conditions or any part of these terms and conditions, you must
            not use our Services.
          </p>
          <p>
            You must be at least 18 years of age to use our Services; by using
            our Services or agreeing to these terms and conditions, you warrant
            and represent that you are at least 18 years of age.
          </p>
          <p>
            Our Services may use cookies; by using our Services or agreeing to
            these terms and conditions, you consent to our use of cookies in
            accordance with our privacy and cookies policy.
          </p>
          <h2>Privacy</h2>
          <p>
            Keeping your account secure and your private information
            confidential is very important to us. Please see our privacy policy
            for more information.
          </p>
          <h2>Registration and Accounts</h2>
          <p>
            You may register for an account with our Services by completing and
            submitting the account registration form on our Services. You must
            not allow any other person to use your account or gain access to the
            Services. Notify us immediately if you become aware of any
            unauthorized use of your account.
          </p>
          <p>
            When you register for an account, you will choose an email address
            and password. Your email address must not be misleading and must
            comply with our content rules. You must not use your account or
            email address to impersonate anyone. Keep your password confidential
            and notify us immediately if you become aware of any disclosure of
            your password. You are responsible for any activity on our Services
            arising from your failure to keep your password confidential.
          </p>
          <p>
            You can only use this app for non-commercial use. You will not give
            your account details or credentials to anyone or transfer your
            account to someone else.
          </p>
          <p>
            We may suspend, delete, or cancel your account at any time in our
            sole discretion without notice or explanation.
          </p>
          <p>
            Refer to our Privacy Policy to understand how you can delete your
            account.
          </p>
          <h2>Licence to Use Services</h2>
          <p>You must not:</p>
          <ul>
            <li>
              Use our Services in any way that causes, or may cause, damage to
              the Services or impairment of the performance, availability or
              accessibility of the Services.
            </li>
            <li>
              Use our Services in any way that is unlawful, illegal, fraudulent
              or harmful, or in connection with any unlawful, illegal,
              fraudulent or harmful purpose or activity.
            </li>
            <li>
              Use our Services to copy, store, host, transmit, send, use,
              publish or distribute any material which consists of (or is linked
              to) any spyware, computer virus, Trojan horse, worm, keystroke
              logger, rootkit or other malicious computer software.
            </li>
            <li>
              Attempt to gain unauthorized access to the Services, the server
              hosting the Services, or any server, computer or database
              connected to the Services, or conduct denial-of-service or
              distributed denial-of-service attacks.
            </li>
            <li>
              Attempt to conduct any systematic or automated data collection
              activities (including without limitation scraping, data mining,
              data extraction and data harvesting) on or in relation to our
              Services without our express written consent.
            </li>
            <li>
              Access or otherwise interact with our Services using any robot,
              spider or other automated means.
            </li>
            <li>
              Use data collected from our Services for any direct marketing
              activity (including without limitation email marketing, SMS
              marketing, telemarketing and direct mailing).
            </li>
            <li>
              Externally or internally record or capture audio content from the
              Sleep Music section hosted by the Services. The music hosted in
              Services is owned by us, meaning any attempt to use other than
              intended personal use within the app is prohibited.
            </li>
          </ul>
          <h2>Your Content</h2>
          <p>
            "Your content" means all materials (including without limitation
            text, graphics, images, audio material, video material, audio-visual
            material, software and files) that you submit to us or our Services
            for storage or publication on, processing by, or transmission via,
            our Services.
          </p>
          <p>
            You acknowledge that you are responsible for all content you
            contribute. You warrant and represent that your content will comply
            with these terms and conditions.
          </p>
          <p>
            Your content must not contain sensitive content or information, be
            illegal or unlawful, infringe any person or organization's legal
            rights, or give rise to legal action against any person or
            organization (in any jurisdiction and under any applicable law).
          </p>
          <p>
            Your content is solely your responsibility, and we are not liable
            for the content itself or any loss or damage of data. You hereby
            release us from all liability.
          </p>
          <h2>Breaches of These Terms and Conditions</h2>
          <p>
            If you breach these terms and conditions in any way, or if we
            reasonably suspect that you have breached these terms and conditions
            in any way, we may temporarily or permanently suspend, terminate,
            delete, restrict access to your account and your content.
          </p>
          <h2>Limited Warranties</h2>
          <p>We do not warrant or represent:</p>
          <ul>
            <li>
              The completeness or accuracy of the information published on our
              Services.
            </li>
            <li>The completeness or accuracy of your content.</li>
            <li>That the material on the Services is up to date.</li>
            <li>
              That the Services are free of viruses or any other potentially
              harmful components.
            </li>
            <li>
              That the Services will remain available. We reserve the right to
              discontinue or alter any or all of our Services, and to stop
              publishing our Services, at any time in our sole discretion
              without notice or explanation. You will not be entitled to any
              compensation or other payment upon the discontinuance or
              alteration of any Services services, or if we stop publishing the
              Services.
            </li>
          </ul>
          <p>
            To the maximum extent permitted by applicable law, we exclude all
            representations and warranties relating to the subject matter of
            these terms and conditions, our Services, and the use of our
            Services. The Services may contain bugs, which shall not be
            considered a breach of these Terms and Conditions. You accept that
            the Services were not developed considering your individual needs
            and it is your responsibility to accept that it meets your needs.
          </p>
          <p>
            We use state-of-the-art hosting and security systems to create a
            secure and safe environment for you and your content. More
            information about the tools we use can be found in our Privacy
            Policy. No system is perfectly secure, and the reliability of our
            architecture and backend or our providers' systems cannot be
            assured. By using our Services, you accept these risks and
            understand that even a state-of-the-art architecture does not
            guarantee perfect security and reliability.
          </p>
          <h2>Limitations and Exclusions of Liability</h2>
          <p>Nothing in these terms and conditions will:</p>
          <ul>
            <li>
              Limit or exclude any liability for death or personal injury
              resulting from negligence.
            </li>
            <li>
              Limit or exclude any liability for fraud or fraudulent
              misrepresentation.
            </li>
            <li>
              Limit any liabilities in any way that is not permitted under
              applicable law.
            </li>
            <li>
              Exclude any liabilities that may not be excluded under applicable
              law.
            </li>
          </ul>
          <p>
            The limitations and exclusions of liability set out in this section
            and elsewhere in these terms and conditions:
          </p>
          <ul>
            <li>Are subject to this section.</li>
            <li>
              Govern all liabilities arising under these terms and conditions or
              relating to the subject matter of these terms and conditions,
              including liabilities arising in contract, in tort (including
              negligence), and for breach of statutory duty, except to the
              extent expressly provided otherwise in these terms and conditions.
            </li>
          </ul>
          <p>
            We will not be liable for any loss or damage of any nature. We will
            not be liable to you in respect of any losses arising out of any
            event or events beyond our reasonable control. We will not be liable
            to you in respect of any losses, including (without limitation) loss
            of or damage to profits, income, revenue, use, production,
            anticipated savings, business, contracts, commercial opportunities,
            or goodwill. We will not be liable to you in respect of any loss or
            corruption of any data, database, or software. We will not be liable
            to you in respect of any special, indirect, or consequential loss or
            damage. You accept that we have an interest in limiting the personal
            liability of our officers and employees, and having regard to that
            interest, you acknowledge that we are a limited liability entity.
            You agree that you will not bring any claim personally against our
            officers or employees in respect of any losses you suffer in
            connection with the Services, Third Party Services used or utilized
            with the Service, or these terms and conditions (this will not, of
            course, limit or exclude the liability of the limited liability
            entity itself for the acts and omissions of our officers and
            employees).
          </p>
          <h2>Variation</h2>
          <p>
            We may revise these terms and conditions from time to time. The
            revised terms and conditions shall apply to the use of our Services
            from the date of publication of the revised terms and conditions on
            the website, and you hereby waive any right you may otherwise have
            to be notified of, or to consent to, revisions of these terms and
            conditions. You are expected to check these Terms and Conditions
            occasionally as you continue using the website, as the updates will
            be binding to you. However, we may post a notification on the
            website when a new version of these Terms and Conditions is live.
          </p>
          <h2>Assignment</h2>
          <p>
            You agree that we may assign, transfer, sub-contract, or otherwise
            deal with our rights and/or obligations under these terms and
            conditions. You may not, without our prior written consent, assign,
            transfer, sub-contract, or otherwise deal with any of your rights
            and/or obligations under these terms and conditions.
          </p>
          <h2>Severability</h2>
          <p>
            If a provision of these terms and conditions is determined by any
            court or other competent authority to be unlawful and/or
            unenforceable, the other provisions will continue in effect. If any
            unlawful and/or unenforceable provision of these terms and
            conditions would be lawful or enforceable if part of it were
            deleted, that part will be deemed to be deleted, and the rest of the
            provision will continue in effect.
          </p>
          <h2>Third Party Rights</h2>
          <p>
            Our Services may link, contain, or use third-party services that are
            not owned or controlled by us. By using the Service, you accept that
            we assume no responsibility for any third-party services or sites or
            for any effect arising from the use of these third-party services.
            You understand and acknowledge that by using our App or our
            Services, you release us from all liability and responsibility,
            directly or indirectly, arising from your use of any third-party
            website, service, or content. By using the Service and accepting
            these terms and conditions, you agree that we won't be responsible
            and cannot be asked to be involved in any dispute between yourself
            and any third party.
          </p>
          <h2>Subscriptions</h2>
          <p>
            Dreamink customers can buy "Premium," a subscription program that
            enables customers to gain access to extra features within the
            Services while the subscription is active. The subscription cycle
            can be monthly or yearly, depending on the customer's choice prior
            to making a purchase. The Premium subscription will automatically
            renew each month or each year. The customer acknowledges that they
            will be charged through their iTunes Account within 24 hours prior
            to the end of the current cycle (including Free Trial). We hold the
            right to cancel any subscription without notice if deemed necessary.
            Some functionality offered by the Services may have a use limit
            applied to prevent excessive requests. You acknowledge that you
            won't be able to make unlimited requests for a particular
            functionality in the Services in any given time period by obtaining
            a Premium subscription or otherwise.
          </p>
          <h2>Free Trial</h2>
          <p>
            If a Free Trial is offered, the customer will be able to use the
            Premium features for a limited period upon enrolling in a Free
            Trial. The Premium features available in the Free Trial may differ
            from the Paid version or may have limited use. If the customer does
            not cancel their Free Trial within 24 hours before its expiry, they
            will be charged for a subscription plan that they have chosen when
            enrolling.
          </p>
          <h2>Cancelling a Subscription</h2>
          <p>
            Customers can turn off auto-renew at any time from their iTunes
            account up until 24 hours before the end of the current subscription
            cycle. Refunds will not be offered for the remainder of their
            subscription cycle.
          </p>
          <h2>Right to Change Subscriptions</h2>
          <p>
            We reserve the right to create and remove any offers, including the
            Free Trial, and change, amend, or adjust pricing for subscription
            offerings for any reason at any time. If such a change occurs, the
            customer will be notified before their next subscription payment,
            and if the customer accepts the changes, they will be charged for
            their upcoming subscription.
          </p>
          <h2>Payment</h2>
          <p>
            All purchases are made through the Apple ecosystem, which means
            Dreamink does not have access to customers' payment information.
          </p>
          <h2>Entire Agreement</h2>
          <p>
            Your use of our Services is at your risk, and you accept that this
            app might be unavailable for use at any time. Subject to Section
            Limitations and Exclusions of Liability, these terms and conditions,
            together with our privacy policy, constitute the entire agreement
            between you and us in relation to your use of our Services and
            supersede all previous agreements between you and us in relation to
            your use of our Services.
          </p>
          <h2>Law and Jurisdiction</h2>
          <p>
            These terms and conditions shall be governed by and construed in
            accordance with English law. Any disputes relating to these terms
            and conditions shall be subject to the jurisdiction of the courts of
            England.
          </p>
        </div>
      </div>
    );
  }
}
