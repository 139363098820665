import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCnYcMdbTezGqrYLiFblQVLBK5NgomW5Jc",
    authDomain: "mindream-17ced.firebaseapp.com",
    projectId: "mindream-17ced",
    storageBucket: "mindream-17ced.appspot.com",
    messagingSenderId: "17604389096",
    appId: "1:17604389096:web:4861ef7ac7a3e2c463815d",
    measurementId: "G-DVC6RSW86S"
  };
  
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const initialState = {
    firebase:app,
    analytics:analytics
};
const rootReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_FB':
        return {
          ...state,
          firebase: action.payload,
        };
    default:
      return state;
    }
  }

export default rootReducer


